@font-face {
    font-family: 'DIN Regular';
    src: local('Din Regular'), url(./fonts/DIN\ Regular.ttf) format('opentype');
  }
  
  @font-face {
      font-family: 'Avenida Std Regular';
      font-weight: 900;
      src: local('Avenida Std Regular'), url(./fonts/Avenida\ Std\ Regular.otf) format('opentype');
  }

  @font-face {
    font-family: 'Icky Ticket Mono';
    src: local('Icky Ticket Mono'), url(./fonts/IckyTicketMono.ttf) format('opentype');
  }